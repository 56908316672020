<script setup>
import { ref } from 'vue'
import { vOnClickOutside } from '@vueuse/components'

const isOpen = ref(false)

function open()   { isOpen.value = true }
function close()  { isOpen.value = false }
function toggle() { isOpen.value = !isOpen.value }
</script>

<template>
    <div
        v-on-click-outside="close"
        :class="['contact-dropdown', { 'contact-dropdown--open': isOpen }]"
    >
        <div
            class="contact-dropdown__header"
            @click="toggle"
        >
            <slot name="header" v-bind="{ open, close, toggle, isOpen }" />
        </div>

        <div class="contact-dropdown__content">
            <transition name="drop-in">
                <ul
                    v-if="isOpen"
                    class="contact-dropdown__options"
                    @click="close"
                >
                    <slot v-bind="{ open, close, toggle, isOpen }" />
                </ul>
            </transition>
        </div>


    </div>


</template>

<style lang="scss">
@use "@/scss/framework" as *;

@layer components.dropdown {

    :root {
        --contact-dropdown-color: var(--color-white-100);
        --contact-dropdown-color--hover: var(--color-white-100);
        --contact-dropdown-background-color: var(--color-royal-blue-100);
        --contact-dropdown-background-color--hover: var(--color-royal-blue-60);
    }

    .contact-dropdown {

        --_color: var(--fab-button-color);
        --_color--hover: var(--fab-button-color--hover);
        --_background-color: var(--fab-button-background-color);
        --_background-color--hover: var(--fab-button-background-color--hover);
        --_transition: var(--duration-slow) var(--easing-default);

        display: inline-flex;
        flex-flow: column;
        align-items: flex-start;
        gap: 0;
        position: relative;
        padding: 0;
        font: var(--font-fab-button);

        &__header {
            position: relative;
            cursor: pointer;
            transition:
                color var(--_transition),
                background-color var(--_transition);
            text-decoration: none;
            text-transform: uppercase;
            color: var(--_color);
            background-color: var(--_background-color);
            z-index: 1;

            .contact-dropdown:not(.contact-dropdown--open) &:hover {
                @media (hover: hover) and (pointer: fine) {
                    color: var(--_color--hover);
                    background-color: var(--_background-color--hover);
                }
            }
        }

        &__content {
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
        }

        &__options {
            position: relative;
            list-style: none;
            margin: calc(-1 * var(--_border-radius)) 0 0;
            padding: inherit;
            background-color: var(--_background-color);
            z-index: 2;

            display: flex;
            flex-flow: column;
            gap: 0;
            overflow: clip;

            a {
                color: inherit;
                text-decoration: none;
            }

            > * {

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        color: var(--_color--hover);
                        background-color: var(--_background-color--hover);
                    }
                }
            }
        }

        &--open {
            .contact-dropdown__header {
                cursor: default;
                background-color: var(--_background-color);
                overflow: clip;
                color: var(--color-blue-zodiac-100, false, true);

                * { color: inherit; }
            }
        }
    }

    .drop-in-enter-active {
        transform-origin: top;
        animation: drop-in var(--duration-slow) var(--easing-decelerate) forwards;
    }

    .drop-in-leave-active {
        transform-origin: top;
        animation: drop-out var(--duration-slow) var(--easing-decelerate) forwards;
    }

    @keyframes drop-in {
        0% { opacity: 0; transform: rotateX(90deg); }
        20% { opacity: 1; }
        100% { opacity: 1; transform: rotateX(0deg); }
    }

    @keyframes drop-out {
        0% { opacity: 1; transform: rotateX(0deg); }
        80% { opacity: 1; }
        100% { opacity: 0; transform: rotateX(90deg); }
    }
}

</style>
